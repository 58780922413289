.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem ;
    margin-left: 2rem;
    margin-right: 2rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2em;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: min-content;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: var(--color-bg-variant-alt);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    color: var(--color-dark);  
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__item-cta .button {
    background: var(--color-darker);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.portfolio__item-cta .button-primary {
    background: var(--color-primary);
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    
    .portfolio__item h3 {
        font-size: 1.4rem;  
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__item h3 {
        font-size: 1.2rem;  
    }
}
