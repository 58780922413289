header {
    padding-top: 7rem;
    overflow: hidden;
    height: 100vh;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* --------- Call To Action ----------- */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.header-name {
    color: var(--color-primary);
}

.header-title {
    margin-top: 1.5rem;
    color: var(--color-white);
}

.header-subtitle {
    margin-top: 1.5rem;
    color: var(--color-primary-variant);
}

.header-title-link {
    color: var(--color-primary);
    text-decoration-line: underline;
}

.header-subtitle-link {
    color: var(--color-primary-variant);
    text-decoration-line: underline;
}


/* --------- Header Socials ----------- */
.header__socials {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 4rem;
    background: var(--color-primary);
}

/* --------- Image ----------- */ /* NEED TO ADD MODIFIERS TO ADDRESS HEIGHTS UNDER MIN SIZE */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 50vw;
    height: 45vh;
    bottom: 0;
    position: absolute;
    left: calc(50% - 25vw);
    margin-top: 4rem;
    border-radius: 25rem 25rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    
}

.me img {
    display: block;
    height: 35vh;
    width: auto;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}



/* --------- Scroll Down ----------- */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 10rem;
    transform: rotate(90deg);
    font-size: 2rem;
    font-style: italic;
    letter-spacing: 1px
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 

    header {
        height: 68rem;
    }
    
    .me {
        width: 50vw;
        height: 45vh;
        bottom: 0;
        position: absolute;
        left: calc(50% - 25vw);
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 5rem 1.5rem 1.5rem 1.5rem;
    }

    .me img {
        height: 30vh;
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 

    header {
        height: 55rem;
        margin-bottom: -8rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .me {
        width: 50vw;
        height: 30vh;
        bottom: 0;
        position: relative;
        left: calc(50% - 25vw);
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 5rem 1.5rem 1.5rem 1.5rem;
    }

    .me img {
        height: 20vh;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2rem;
    }
}

.waviy {
    position: relative;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    font-size: 40px;
    color: #fff;
    animation: flip 5s infinite;
    animation-delay: calc(.2s * var(--i))
  }
  @keyframes flip {
    0%,80% {
      transform: rotateY(360deg) 
    }
  }